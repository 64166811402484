import React from "react"
import { graphql } from "gatsby"
import { getHrefLangs } from "../core/QueryTransformer"

import rehypeReact from "rehype-react"
import Bubble from "../components/bubble"
import SimpleBox from "../components/simple-box"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    bubble: Bubble,
    box: SimpleBox,
  },
}).Compiler

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext: { pages, locale, home },
}) {
  // extract data for the location post
  const { locationPost, hreflangEdges, homePage, regionPost } = data
  const { frontmatter, htmlAst } = locationPost

  return (
    <>    
      <a><h1>{frontmatter.heading}</h1></a>
      <div className="amp-header-image-container">
        <amp-img
          alt={frontmatter.metatitle}
          src={`/images/${frontmatter.slug}.jpg`}
          height="400" width="800"
          layout="responsive"
        >
        </amp-img>
      </div>
      <div>{renderAst(htmlAst)}</div>
    </>    
  )
}

export const pageQuery = graphql`
  query($url: String!, $locale: String!, $slug: String!, $region: String!) {
    locationPost: markdownRemark(
      frontmatter: { url: { eq: $url }, locale: { eq: $locale } }
    ) {
      htmlAst
      frontmatter {
        title
        heading
        metatitle
        locale
        region
        type
        url
        description
        metadescription
        slug
        from
        to
        #featuredImage {
        #   childImageSharp {
        #     fluid(maxWidth: 800) {
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        #}
      }
    }
    regionPost: markdownRemark(
      frontmatter: {
        locale: { eq: $locale }
        region: { eq: $region }
        type: { eq: "region" }
      }
    ) {
      frontmatter {
        title
        url
      }
    }
    homePage: markdownRemark(
      frontmatter: { locale: { eq: $locale }, type: { eq: "homepage" } }
    ) {
      frontmatter {
        title
        url
      }
    }
    hreflangEdges: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          frontmatter {
            url
            locale
          }
        }
      }
    }
  }
`
